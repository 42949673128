.hero {
  background-image: linear-gradient(180deg, #f6f6f6 21.35%, rgba(255, 255, 255, 0) 100%),
    url("../assets/heroc.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  color: var(--color-primary-text);
  padding-bottom: calc(100% - 190px);
}

.testimonianze-cta {
  background-color: #06282082;
  background-image: url("../assets/testimonianze.jpg");
  background-blend-mode: multiply;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  color: #ffffff;
}

/* ---- home carousel ---- */
.hero-carousel-wrapper > .swiper {
  border-radius: 0;
}

.hero-carousel-wrapper > .swiper > .swiper-button-next,
.hero-carousel-wrapper > .swiper > .swiper-button-prev {
  border-radius: var(--border-rad);
  color: var(--color-primary);
  padding: 30px 16px;
  margin-top: calc(0px - (var(--swiper-navigation-size) / 2));
}

.hero-carousel-wrapper > .swiper > .swiper-button-next:hover,
.hero-carousel-wrapper > .swiper > .swiper-button-prev:hover {
  background-color: #00000011;
}

.hero-carousel-wrapper > .swiper > .swiper-pagination > .swiper-pagination-bullet {
  background: #000000;
  opacity: 0.4;
}
.hero-carousel-wrapper > .swiper > .swiper-pagination > .swiper-pagination-bullet-active {
  background: #000000;
  opacity: 1;
}

.hero-carousel-wrapper > .swiper > .swiper-button-prev {
  display: none;
}
.hero-carousel-wrapper > .swiper > .swiper-button-next {
  display: none;
}
/* ---- home carousel ---- */

@media (min-width: 768px) {
  .hero-carousel-wrapper > .swiper > .swiper-button-prev {
    display: flex;
    margin-left: 20px;
  }
  .hero-carousel-wrapper > .swiper > .swiper-button-next {
    display: flex;
    margin-right: 20px;
  }
}

@media (min-width: 1024px) {
  .hero {
    background-image: linear-gradient(90deg, #f6f6f6 21.35%, rgba(255, 255, 255, 0) 100%),
      url("../assets/heroc.jpg");
    padding-top: calc(50vh - 178px);
    padding-bottom: calc(50vh - 178px);
  }
  .hero-carousel-wrapper > .swiper > .swiper-button-prev {
    top: 10% !important;
    left: 90% !important;
    margin-left: 0 !important;
  }

  .hero-carousel-wrapper > .swiper > .swiper-button-next {
    top: 10% !important;
    right: 3% !important;
    margin-right: 0 !important;
  }
  .hero-carousel-wrapper > .swiper > .swiper-button-prev {
    top: 10% !important;
    left: 90% !important;
    margin-left: 0 !important;
  }

  .hero-carousel-wrapper > .swiper > .swiper-button-next {
    top: 10% !important;
    right: 3% !important;
    margin-right: 0 !important;
  }
}

/* if device has no hover it's probably a mobile */
@media (hover: none) {
  .testimonianze-cta {
    background-attachment: scroll;
  }
}
